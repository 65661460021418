import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from 'react-helmet'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {Link} from "gatsby"
import { TimerOutline, CutOutline, RefreshOutline, ColorWandOutline, TodayOutline, GiftOutline, FlowerOutline, ShuffleOutline } from 'react-ionicons'
import { PersonCircleOutline } from 'react-ionicons'
import ReactPlayer from 'react-player'
import Auth from '../components/Auth'
import Footer from '../components/Footer'
import LastDelivery from '../components/LastDelivery'
import Testimonials from '../components/Testimonials'
import Countdown from 'react-countdown';

class Form extends React.Component {
  state = {
    email: null,
    msg: null,
    loading: false
}

_handleChange = e => {
    // console.log({
    //     [`${e.target.name}`]: e.target.value,
    // })
    this.setState({
        [`${e.target.name}`]: e.target.value,
    })
}
_handleCloseMessage = e => {

    this.setState({
        msg: null,
    })
}

_handleSubmit = e => {
    e.preventDefault()
    this.setState({
        msg: null,
        loading: true
    })
    // console.log('submit', this.state)

    addToMailchimp(this.state.email, this.state)
        .then(({ msg, result }) => {
            console.log('msg', `${result}: ${msg}`)

            if (result !== 'success') {
                throw {success: false, content: "Lūdzu ievadi pareizu e-pastu, vai arī šis e-pasts jau ir pierakstīts informācijas saņemšanai."}
            }
            // alert(msg)
            throw {success: true, content: 'Mēs uz norādītu e-pasta adresi nosūtīsim paziņojumu, kad būsim sākuši darbu. Tikmēr vari sekot mūs Instagram.'}
        })
        .catch(err => {
            // console.log('err', err)

            this.setState({
                msg: err,
                loading: false
            })
            // alert(err)
        })
}
  render() {
      return (
<form class="mt-5 subscription-form" onSubmit={this._handleSubmit}>

{this.state.msg ? <article class={this.state.msg.success ? " message is-success" : "message is-warning"}>
  <div class="message-header">
    <p>{this.state.msg.success ? "Paldies!" : "Kļūda"}</p>
    <button class="delete" aria-label="delete" onClick={this._handleCloseMessage}></button>
  </div>
  <div class="message-body">
    {this.state.msg.content}
  </div>
</article> : null}

<div class="columns">
  <div class="column">
  <div class="field ">
  <label class="label is-sr-only">Email</label>
  <div class="control">
    <input class="input  is-rounded" type="email"  name="email" placeholder="Ieraksti savu e-pastu" onChange={this._handleChange} />
  </div>
</div>

  </div>
  <div class="column">

  <button type="submit" class={this.state.loading ? "button is-primary  is-fullwidth is-rounded is-loading" : "button is-primary  is-fullwidth is-rounded"} >Pierakstīties</button>

  </div>
</div>


</form>
      )
  }
}




// markup
const IndexPage = () => {

    const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      visa: file(relativePath: { eq: "visa.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      applepay: file(relativePath: { eq: "apple-pay.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      mc: file(relativePath: { eq: "mc.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      googlepay: file(relativePath: { eq: "google-pay.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },

      arrange: file(relativePath: { eq: "photos/pexels-cottonbro-4270179.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      flat: file(relativePath: { eq: "photos/pexels-karolina-grabowska-4207709.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      },



      samplewhite: file(relativePath: { eq: "photos/samples/white.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      sampleorange: file(relativePath: { eq: "photos/samples/orange.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },

      step1: file(relativePath: { eq: "photos/steps/step1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      step2: file(relativePath: { eq: "photos/steps/step2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      step3: file(relativePath: { eq: "photos/steps/step3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },



      one: file(relativePath: { eq: "photos/compositions/trial1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      two: file(relativePath: { eq: "photos/compositions/trial2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      three: file(relativePath: { eq: "photos/compositions/week1.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    const closingDate = new Date(2021, 1, 12, 23, 59, 59);

  return (
    <main>
      <Helmet title="Heyday ziedu piegāde" defer={false} />
      <Auth silent></Auth>
      {/* <section class="bar">
        Nākamā piegāde 3. jūnijā
      </section> */}


      <section class="hero is-danger is-medium">
          <div class="hero-head">

            <div className="container">
              <div className="columns is-mobile mt-2">
                <div className="column"></div>
                <div className="column has-text-centered pt-5"><Img fixed={data.logo.childImageSharp.fixed} alt="Heyday" /> </div>
                <div className="column pt-4">
                  <div class="is-pulled-right ">
                    <Link to="/manage" class="icon is-large">
                        <PersonCircleOutline color="#874CD9" />
                    </Link>
                  </div>

                </div>
              </div>

            </div>


          </div>
          <div class="hero-body">
          
          

          <div class="container has-text-left">

            <h1 class="title is-1 ">
              Saņem svaigus ziedus kaut katru nedēļu
            </h1>
            <p class="subtitle">
              Ziedu piegādes abonements
            </p>
            <div class="has-text-left has-text-centered-mobile">
            <p>Atstāj savu e-pastu, mēs paziņosim, kad abonements būs pieejams.</p>
            <p>Pieraksties tagad - <strong>saņem 10% atlaidi.</strong> pirmajam pirkumam</p>
          </div>
        <Form />

              {/* <Link to="/subscribe" class="button is-primary is-rounded">Izmēģināt abonementu</Link> */}
            </div>
          </div>
      </section>

      <LastDelivery />


      <div class="container">




       

        {/* 
        <Img fluid={data.file.childImageSharp.fluid} alt="asd" />  */}
      </div>

      <div class="container">
      <section class="section">

      {/* <h2 class="title is-2">Iknedēļas ziedu rituāls</h2> */}
      <h2 class="title is-2">Kā tas strādā</h2>

        <div className="columns is-multiline how-it-works">
          <div className="column is-one-third-tablet">
            <div className="columns is-mobile">
              <div className="column is-narrow"><RefreshOutline /> </div>
              <div className="column">Izvēlies piegādes biežumu - <strong>1, 2 vai 4 reizes mēnesī</strong></div>
            </div>
          </div>
          {/* <div className="column is-one-third-tablet">
            <div className="columns is-mobile">
              <div className="column is-narrow"><ColorWandOutline /> </div>
              <div className="column">Florists sagatavo ziedus burvīgām kompozīcijām</div>
            </div>
          </div> */}
          <div className="column is-one-third-tablet">
            <div className="columns is-mobile">
              <div className="column is-narrow"><TodayOutline /> </div>
              <div className="column">Piegādājam tos <strong>katru ceturtdienu</strong></div>
            </div>
          </div>
          <div className="column is-one-third-tablet">
            <div className="columns is-mobile">
              <div className="column is-narrow"><GiftOutline /> </div>
              <div className="column"><strong>Kurjers piegādā</strong> ziedus dāvanu iepakojumā</div>
            </div>
          </div>
          <div className="column is-one-third-tablet">
            <div className="columns is-mobile">
              <div className="column is-narrow"><FlowerOutline /> </div>
              <div className="column"><strong>Sakārto ziedus pati</strong>, sekojot pievienotajai video instrukcijai</div>
            </div>
          </div>
          <div className="column is-one-third-tablet">
            <div className="columns is-mobile">
              <div className="column is-narrow"><ShuffleOutline /> </div>
              <div className="column">Atcel un maini abonementu bez maksas un bez ierobežojumiem</div>
            </div>
          </div>
          {/* <div className="column"> </div>
          <div className="column"></div>
          <div className="column"></div>
          <div className="column"></div>
          <div className="column"></div> */}
        </div>

        {/* <Img fluid={data.video.childImageSharp.fluid} alt="Video instructions" class="video" />  */}

        <div data-sal="fade">


        <ReactPlayer
        url='https://vz-da54803c-c33.b-cdn.net/a985f034-9783-47cf-8a36-3fedaec6b6b7/playlist.m3u8'
        width='100%'
        height='100%'
        muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                    autoplay: false,
                    preload: 'none',
                    poster: 'https://vz-da54803c-c33.b-cdn.net/a985f034-9783-47cf-8a36-3fedaec6b6b7/thumbnail.jpg'
                    // poster: 'https://vz-da54803c-c33.b-cdn.net/a985f034-9783-47cf-8a36-3fedaec6b6b7/preview.webp'
                },
                // hlsOptions: {
                  // autoStartLoad: false
                // }
            },
            tracks: [
                { kind: 'captions', src: '/titles/003.vtt', label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />
          {/* <ReactPlayer url="https://share.vidyard.com/watch/iH1xc54g5b9AFrBc9zkZVr" controls={false} muted={true} playsinline={true} width='100%' height='100%' /> */}



          <p class="has-text-centered">Piemērs video instrukcijai</p>
        </div>


   

        {/* <div class="columns">


          <div class="column">
              <p class="title is-4">Florists izveido nedēļas kompozīciju</p>
              <p class="subtitle">Mēs sadarbojamies ar vietējiem floristiem.</p>
          </div>
          <div class="column">
            <p class="title is-4">Ziedi tiek sagatavoti izsūtīšanai</p>
              <p class="subtitle">Tikko ziedi nonāk mūsu bāzē, mēs tos sagatavojam (sašķirojam pēc florista norādījumiem, attīram no lapām), un tajā pašā dienā nosūtam Tev. Katru nedēļu, piegāde notiek konkrētā nedēļas dienā.</p>

          </div>
          <div class="column">
              <p class="title is-4">Kurjers piegādā ziedus līdz mājas durvīm</p>
                <p class="subtitle">Ziedus vāzē sakārtosiet paši. Kopā ar ziediem, saņemsi instrukciju kā tos sakārtot vāze un kopt, lai tie ilgāk būtu svaigi.</p>

          </div>
          <div class="column">
              <p class="title is-4">Tu izvēlies, cik bieži vēlies saņemt ziedus</p>
                <p class="subtitle">Iespēja saņemt piegādi 1, 2 vai 4 reizes mēnesī, lai mājās vienmēr Tevi iepriecina svaigi ziedi.</p>

          </div>

        </div> */}

        </section>
      </div>

      <Testimonials />

      <section class="section" style={{backgroundColor: '#F0E2D7'}}>
        <div className="container content">
          <h2 class="title is-2">Tevis sakārtoti ziedi</h2>
          <p class="subtitle">Ar mūsu palīdzību, iemācies ziedu kompozīciju veidošanu</p>


          <div className="columns">
            <div className="column is-two-fifths-tablet">

                  <div className="columns is-mobile">
                    <div className="column is-narrow"><TimerOutline /></div>
                    <div className="column"><p>Saņemtos ziedus sakārto 3-5 minūšu laikā</p></div>
                  </div>
                  
                  
                <div className="columns is-mobile">
                    <div className="column is-narrow"><CutOutline /></div>
                    <div className="column"><p>Nepieciešamas tikai šķēres un vāze. Ar ziediem saņemsi barības vielu pulveri</p></div>
                  </div>
                  


            </div>
            <div className="column">

              <div className="columns is-mobile">
                <div className="column" data-sal="fade">
                  <p><Img fluid={data.step1.childImageSharp.fluid} class="sample" /></p>
                  <p>Atsaiņo saņemtos ziedus</p>
                </div>
                <div className="column" data-sal="fade">
                  <p><Img fluid={data.step2.childImageSharp.fluid} class="sample" /></p>

                  <p>Sagarini ziedus, lai tie derētu vāzei</p>
                </div>
                <div className="column" data-sal="fade">
                  <p><Img fluid={data.step3.childImageSharp.fluid} class="sample" /></p>
                  <p>Seko video instrukcijai vai sakārto pēc saviem ieskatiem</p>
                </div>
              </div>
            </div>
          </div>



        </div>
      </section>

      <section class="section">
        <div class="container content">

          <h2 class="title is-2">Ziedu abonements</h2>

          <div class="columns">
              
               <div class="column is-two-fifths">
               {/* <div class="box">
            Atlikušas <strong><Countdown 
              date={closingDate} 
              daysInHours
              renderer={(props) => {
                return (<span>
                  {(props.total/1000) > (60 * 60)
                    ? `${props.hours + props.days * 24} stundas un ${props.minutes} minūtes`
                    : `${props.minutes} minūtes` }
                  
                </span>)
              }}
              /></strong> līdz iespējai iegādāties ziedu piegādi 13. vai 14. februārī

          </div> 

              <hr/>
              Iespējama piegāde 13. februārī, 14:00-20:00, un 14. februārī, 08:00-16:00.
          </div>
*/}

                <ul>
                <li class="">Piegāde 1, 2 vai 4 reizes mēnesī</li>
                <li class="">Svaigi ziedi, kas tikko piegādāti no ziedu audzētavas</li>
                <li>Kurjerpiegāde visiem klientiem</li>
                <li class="">Apmierinātības garantija</li>
                <li class="">Iespējams nosūtīt kā lielisku dāvanu, kas priecēs ilgi</li>
                </ul>
              </div>

              <div class="column has-text-centered ">
                <div className="box sub" data-sal="fade">
                  <p class="title is-1 has-text-weight-light price mb-3">€35 <small class="is-size-6">Par reizi</small></p>
                  {/* <p class="has-text-centered">
                    <Link to="/subscribe" class="button is-primary is-rounded">Pirkt tagad</Link>
                  </p> */}
                  <Img fixed={data.visa.childImageSharp.fixed} class="Visa logo" /> &nbsp;
                  <Img fixed={data.mc.childImageSharp.fixed} class="Mastercard logo" /> &nbsp;
                  <Img fixed={data.googlepay.childImageSharp.fixed} class="Google Pay logo" /> &nbsp;
                  <Img fixed={data.applepay.childImageSharp.fixed} class="Apple Pay logo" /> 

                </div>


                <div class="columns">

                <div class="column ">
                  <p class="title is-4 has-text-weight-light price mb-3">Rīga</p>
                  <p>Piegāde bezmaksas</p>

                </div>


                <div class="column ">
                  <p class="title is-4 has-text-weight-light price mb-3">Jūrmala, Piņķi, Mārupe, Jaunmārupe, Berģi, Baltezers, Ādaži, Carnikava</p>
                  <p>Piegādes maksa €5</p>


                </div>

                </div>
    
              </div>
              

          </div>



          </div>
        </section>


        {/* <section class="section">
          <div class="container content">

            <h2 class="title is-2">Piegāde</h2>

            <div class="columns">

                <div class="column ">
                  <p class="title is-4 has-text-weight-light price mb-3">Rīga</p>
                  <p>€0 (bezmaksas)</p>

                </div>
                
                
                <div class="column ">
                  <p class="title is-4 has-text-weight-light price mb-3">Jūrmala, Piņķi, Mārupe, Jaunmārupe, Berģi, Baltezers, Ādaži</p>
                  <p>€5</p>


                </div>

            </div>

          </div>
        </section> */}



      {/* <div class="container content">

        <section class="section">
        
          <div class="columns">

              <div class="column content">
                  <h2 class="title is-2">Ziedi no vietējiem floristiem</h2>
                  <p>Mēs sadarbojamies ar labākajiem floristiem, kuri rada pasakainas ziedu kompozīcijas.</p>
                  <p>Saviem klientiem spējam nodrošināt izdevīgāku cenu, jo ziedus iegādājamies vairumā. Turklāt tos piegādājam ar kurjeru līdz mājas durvīm.</p>
              </div>
              
              
              <div class="column is-half">
                  <Img fluid={data.flat.childImageSharp.fluid} class="example" /> 
              </div>

          </div>

          </section>

        </div> */}



        {/* <div class="container content" style={{backgroundColor: '#F0E2D7'}}>

        <section class="section has-text-centered">



              <div class="  content">
                  <h2 class="title is-2">Radošas izpausmes brīvība, sakārtojot saņemtos ziedus</h2>
                  <p>Viss gaumīgai kompozīcijai - svaigi ziedi un florista izveidota skaidra instrukcija to sakārtošanai vāzē.</p>
              </div>
              
              


          </section>

        </div> */}




      <div class="container">

        <section class="section content">

          <h2 class="title is-2">Ko iepriekš saņēmuši mūsu klienti?</h2>

          <div className="columns">
            <div className="column is-two-fifths">

                <p>Katru nedēļu sagatavojam unikālu ziedu salikumu. Tie ir 20-30 svaigi, dažādi, saskaņoti ziedi un zaļumi, ko viegli sakārtot vāzē. Ziedu skaits var mainīties, bet no tiem varēs izveidot vienu lielu, vai divas mazākas kompozīcijas.</p>

                {/* <p class="mt-5 is-hidden-mobile">
                  <Link to="/subscribe" class="button is-primary is-rounded">Iegādāties abonementu</Link>
                </p> */}
            </div>
            <div className="column">

            <div class="columns is-mobile">

                <div class="column" data-sal="fade">
                    <p><Img fluid={data.one.childImageSharp.fluid} class="sample" /></p>
                    <p>23 ziedi</p>
                    <p class="latin">Rozes harmony peach, waxflower, aromātiskās genista pink, alstromērijas un zaļumi</p>
                </div>

                <div class="column " data-sal="fade">
                    <p><Img fluid={data.two.childImageSharp.fluid} class="sample" /></p>
                    <p>29 ziedi</p>
                    <p class="latin">Rozes baltā, gaiši rozā, sarkanā krāsā, waxflower, alstromērijas, pandas un parvifolia eikalipts</p>
                </div>

                <div class="column " data-sal="fade">
                    <p><Img fluid={data.three.childImageSharp.fluid} class="sample" /></p>
                    <p>23 ziedi</p>
                    <p class="latin">Āzijas gundega jeb Ranunculus, lefkojas, lizantes, rozes aqua, brunia albiflora, vaska puķe un parvifolia eikalipts</p>
                </div>


                </div>
            </div>
          </div>


        
         
          <p class="has-text-centered mt-5 is-hidden-tablet">
            <Link to="/subscribe" class="button is-primary is-rounded">Iegādāties abonementu</Link>
          </p>
          </section>

        </div>


        {/* <section class="section us">
      <div class="container content">

        
          <div class="columns">

              <div class="column  content">
                  <h2 class="title is-2">Mēs padarām ziedu iegādi vienkāršu</h2>
                  <p>Ziedi ir vienkāršākais veids, kā iepriecināt sevi vai savus mīļos. Mēs to padaram daudz vienkāršāku.</p>
                  <p>Ziedi tiek piegādāti skaisti un gaumīgi iepakoti, tādējādi lieliski derēs arī kā dāvana.</p>
              </div>
              
              <div class="column is-half" data-sal="fade">
                  <Img fluid={data.arrange.childImageSharp.fluid} class="example" /> 
              </div>
              

          </div>


        </div>
          </section> */}





        <section class="section has-text-left" style={{backgroundColor: '#F0E2D7'}}>
          <div class="container content">

            <p class="title is-3">Pieraksties, lai saņemtu ziņas par īpašiem piedāvājumiem</p>
            {/* <p>Pieraksties, lai saņemtu ziņu, kad sāksim darbu.</p> */}
            <Form />

          </div>
        </section>



      <div class="container content">

      <section class="section">
        <h2 class="title is-2">Papildus informācija</h2>


        <p class="title is-6">Vai varēšu izvēlēties ziedus, ko saņemu?</p>
        <p class="mb-5">
          Ik nedēļu kompozīcija mainīsies, tāpēc visi tiks iepriecināti ar mīļākajiem ziediem.  pārsteiguma prieka radīšanas. Vēlamies sagādāt Jums pārsteigumu
          Mēs vadāmies pēc vislabākas gaumes un apvienojam vienā piegādē dažādus ziedus, pat ja arī kāds zieds nepatiktu, tas nebūtu dominējošais. Turklāt šī ir lieliska iespēja iepazīt jaunus ziedus un to kompazīcijas.
          
          </p>


        <p class="title is-6">Kā notiek ziedu piegāde?</p>
        <p class="mb-5">
          Ziedi tiks piegādāti konkrētā dienā, par kuru zināsi jau iepriekš. Piegādes dienā, no rīta saņemsi īsziņu ar gaidāmo piegādes laiku. Pēc tam papildus paziņojumu neilgi pirms kurjera ierašanās. Ja ir nepieciešams mainīt piegādes laiku, īsziņā būs informācija kā to izdarīt
        </p>

        <p class="title is-6">Kā saņemšu video instrukciju?</p>
        <p class="mb-5">
          Reizē ar ziediem, saņemsi kartiņu vai ziņu uz savu telefona numuru ar saiti, kurā varēsi apskatīt video instrukciju ar padomiem par kompozīcijas izveidošanu un pareizi ziedu kopšanu.
        </p>

        <p class="title is-6">Atteikuma tiesības</p>
        <p class="mb-5">Mums rūp Jūsu pieredze, tāpēc izskatīsim visas atsauksmes un komentārus par ziedu abonementu vai to piegādi.</p>
        <ul>
          <li>Ja saņemtie ziedi būs bojāti, divu dienu laikā vari pieteikt atsauksmi, un mēs to izskatīsim, lai labotu situāciju.</li>
          <li>Ziedu abonementu vari pārtraukt pirms katras nākamās piegādes.</li>
        </ul>

        <p class="title is-6">Kā notiek maksājums?</p>
        <p class="">Maksājums notiek automātiski, aptuveni 48 stundas pirms katras piegādes. Atkarībā no abonementa regularitātes, tas var notikt dažas dienas agrāk. Pamatprincips ir viens - ziedu apmaksa notiek pirms ziedu piegādes.</p>
        <p>Ja gadījumā, automātiskā apmaksa pieprasa Tavu apstiprinājumi, Tu saņemsi e-pastu no maksājumu platformas Stripe, ar instrukciju.</p>

        


        <p class="title is-6">Kur meklēt papildus informāciju?</p>
        <p class="">Droši raksti mums uz adresi <a href="mailto:info@heyday.lv">info@heyday.lv</a> vai <a href="call:+37122083434">zvani</a>.</p>

        </section>

      </div>





      <Footer />
    </main>
  )
}

export default IndexPage
