import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const LastDelivery = () => {

    const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "photos/week1/week1-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      two: file(relativePath: { eq: "photos/week1/week1-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      three: file(relativePath: { eq: "photos/week1/week1-3.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      four: file(relativePath: { eq: "photos/week1/week1-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      five: file(relativePath: { eq: "photos/week1/week1-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      six: file(relativePath: { eq: "photos/week1/week1-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (

      <section class="examples is-unselectable ">
       <div class="container is-fluid-tablet is-fluid-desktop is-fluid-fullhd is-max-desktop">
        <div class="columns is-multiline is-mobile is-variable is-0-mobile  has-text-centered scrolling ">
          <div class="column pb-0 pt-0 is-one-third-mobile is-hidden-mobile">
            <Img fluid={data.two.childImageSharp.fluid} class="example" /> 
          </div>
          <div class="column pb-0 pt-0 is-one-third-mobile">
            <Img fluid={data.one.childImageSharp.fluid} class="example" /> 
          </div>
          <div class="column pb-0 pt-0 is-one-third-mobile">
            <Img fluid={data.three.childImageSharp.fluid} class="example" /> 
          </div>
          <div class="column pb-0 pt-0 is-one-third-mobile is-hidden-mobile">
            <Img fluid={data.four.childImageSharp.fluid} class="example" /> 
          </div>
          <div class="column pb-0 pt-0 is-one-third-mobile is-hidden-mobile">
            <Img fluid={data.five.childImageSharp.fluid} class="example" /> 
          </div>
          <div class="column pb-0 pt-0 is-one-third-mobile ">
            <Img fluid={data.six.childImageSharp.fluid} class="example" /> 
          </div>

        </div>
        </div>
      </section>


  )
}

export default LastDelivery
