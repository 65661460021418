import * as React from "react"
import Img from "gatsby-image"
import { Star } from 'react-ionicons'

const Stars = () => {
    return (<>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
    </>)
}

const Testimonials = () => {


  return (

    <section class="section has-text-left pt-0" style={{backgroundColor: '#fff'}}>
    <div class="container content">

      <p class="title is-2">Klientu atsauksmes</p>

    <div className="testimonials has-text-centered">

        <div className="columns">
            <div className="column">
                <p>Fantastiska prezentācija un serviss, ceru ka tas saglabāsies tikpat labā līmenī. Viegli sekot instrukcijai un ļoti skaists rezultāts.</p>
                <Stars />

            </div>
            <div className="column">
                <p>Ļoti patika, ka ir gan ziedi, gan daudz zaļumu, un ka ir "nestandarta" ziedi, kurus nav tik viegli nopirkt jebkurā ziedu veikalā.</p>
                <Stars />
            </div>
            <div className="column">
                <p>Forši bija arī tas, ka kompozīcijā bija smaržīgs elements, izkārtošanas laikā papildus bauda. Iepakojums, kartiņa, lentītes, konfekte - viss labi pārdomāts un ļoti gaumīgs, uzlabo garastāvokli :) </p>
                <Stars />
            </div>
        </div>

        
        

        
    </div>



    </div>
  </section>


  )
}

export default Testimonials
